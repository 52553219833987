
export default {
  namespaced: true,
  state: {
    price_type_list: [
      { name: 'uah', percent: 0 },
      { name: 'fop', percent: 3 },
      { name: 'nds', percent: 12 }
    ],
    selected_type: localStorage.getItem('etalon-price-type') || 0
  },
  getters: {
    price_type (state) {
      return state.price_type_list[state.selected_type]
    }
  },
  mutations: {
    setPriceType (state, index) {
      state.selected_type = index
      localStorage.setItem('etalon-price-type', index)
    }
  },
  actions: {

  }
}
